// useProjectCount.js
import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';

export function useProjectCount(userAccount) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!userAccount) return;
    const fetchCount = async () => {
      try {
        const projectsRef = collection(db, "projects", userAccount, "ProjectRef");
        const q = query(projectsRef, where("userId", "==", userAccount));
        const querySnapshot = await getDocs(q);
        setCount(querySnapshot.size);
      } catch (error) {
        console.error("Error fetching project count:", error);
      }
    };
    fetchCount();
  }, [userAccount]);

  return count;
}
