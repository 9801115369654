import React, { useState, useEffect } from 'react';
import './DiscordCarousel.css';

function DiscordCarousel() {
  // Define your slides data in an array
  const slides = [
    {
      title: 'Join Our Discord Community',
      subtitle: 'Connect with creators, share ideas, and get support from the community.',
      link: 'https://discord.gg/BSgQ6whg8u',
      buttonText: 'Join Us',
      buttonIcon: 'bi bi-discord', // Classe de l'icône Bootstrap pour Discord
    },
    {
      title: 'Follow Us on Twitter',
      subtitle: 'Stay updated with the latest news, events, and community discussions.',
      link: 'https://x.com/dappzy_io',
      buttonText: 'Follow Us',
      buttonIcon: 'bi bi-twitter-x', // Classe de l'icône Bootstrap pour Twitter
    },
    {
      title: 'Follow Us on LinkedIn',
      subtitle: 'Connect professionally, explore opportunities, and engage with industry leaders.',
      link: 'https://www.linkedin.com/company/dappzy',
      buttonText: 'Follow Us',
      buttonIcon: 'bi bi-linkedin', // Classe de l'icône Bootstrap pour LinkedIn
    },
  ];

  // Keep track of the current slide index
  const [currentSlide, setCurrentSlide] = useState(0);

  // Move to the next slide automatically every 4 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide(prev => (prev + 1) % slides.length);
    }, 4000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [slides.length]);

  // Handlers to move to previous/next slide (if you still want manual controls)
  const goToPrevSlide = () => {
    setCurrentSlide(prev => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const goToNextSlide = () => {
    setCurrentSlide(prev => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  // Current slide data
  const { title, subtitle, link, buttonText, buttonIcon } = slides[currentSlide];

  return (
    <div className="dashboard-user-pages-content-bloc du-bloc-content carousel-container">
      {/* LEFT/CENTER: Slide content */}
      <div className="du-bloc-content-card du-discord-card">
        <div className="du-bloc-content-card-text-box">
          <p className="du-bloc-content-card-title du-discord-title">
            {title}
          </p>
          <p className="du-bloc-content-card-text du-discord-subtitle">
            {subtitle}
          </p>
        </div>
        <div className="du-bloc-content-card-right">
          <a
            className="du-discord-button"
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            <i className={buttonIcon}></i> {buttonText}
          </a>
        </div>
      </div>

      {/* RIGHT: Carousel controls, slide monitor, and dots */}
      <div className="carousel-right-panel">
        {/* 4 Dots indicating which slide is active */}
        <div className="carousel-dots">
          {slides.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentSlide ? 'active' : ''}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default DiscordCarousel;
