import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../../firebaseConfig';
import './DUEmailBloc.css';

function DUEmailBloc({ userAccount }) {
  const [toggles, setToggles] = useState({
    accountUpdates: true,
    generalUpdates: true
  });
  const [isModified, setIsModified] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  // Fetch initial email preferences from Firestore.
  useEffect(() => {
    if (!userAccount) return;
    const fetchPreferences = async () => {
      try {
        const userRef = doc(db, "users", userAccount);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const data = userSnap.data();
          if (data.profile && data.profile.emailPreferences) {
            setToggles(data.profile.emailPreferences);
          }
        }
      } catch (error) {
        console.error("Error fetching email preferences:", error);
      }
    };
    fetchPreferences();
  }, [userAccount]);

  const handleToggle = (toggleName) => {
    setToggles(prevState => ({
      ...prevState,
      [toggleName]: !prevState[toggleName]
    }));
    setIsModified(true);
    setIsSaved(false);
  };

  const handleSave = async () => {
    if (!userAccount) return;
    try {
      const userRef = doc(db, "users", userAccount);
      await updateDoc(userRef, {
        "profile.emailPreferences": toggles,
        lastUpdated: serverTimestamp()
      });
      setIsModified(false);
      setIsSaved(true);
    } catch (error) {
      console.error("Error updating email preferences:", error);
    }
  };

  // When isSaved becomes true, reset it to false after 3 seconds.
  useEffect(() => {
    if (isSaved) {
      const timer = setTimeout(() => {
        setIsSaved(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSaved]);

  return (
    <div className="dashboard-user-pages-content-bloc">
      <div className="dashboard-user-pages-content-bloc-top">
        <p className='dashboard-user-pages-content-bloc-top-title'>Email Preferences</p>
        {isModified && (
          <a
            className="dashboard-user-pages-content-bloc-edit-btn-save"
            onClick={handleSave}
          >
            {isSaved ? <>Saved<i className="bi bi-check-lg"></i></> : "Save"}
          </a>
        )}
      </div>
      <div className='du-email-bloc-content'>
        <div className='du-email-bloc-content-card'>
          <div className='du-email-bloc-content-card-left'>
            <div className='du-email-bloc-content-card-icon-box'>
              <i className="bi bi-person-fill-gear"></i>
            </div>
            <div className='du-email-bloc-content-card-text-box'>
              <p className='du-email-bloc-content-card-title'>Account Updates</p>
              <p className='du-email-bloc-content-card-text'>Receive important account-related notifications.</p>
            </div>
          </div>
          <div className='du-email-bloc-content-card-right'>
            <label className="du-toggle-switch">
              <input type="checkbox" checked={toggles.accountUpdates} onChange={() => handleToggle('accountUpdates')} />
              <span className="du-slider round"></span>
            </label>
          </div>
        </div>
        <div className='du-email-bloc-content-card'>
          <div className='du-email-bloc-content-card-left'>
            <div className='du-email-bloc-content-card-icon-box'>
              <i className="bi bi-envelope-fill"></i>
            </div>
            <div className='du-email-bloc-content-card-text-box'>
              <p className='du-email-bloc-content-card-title'>General Updates</p>
              <p className='du-email-bloc-content-card-text'>Stay informed about new features and announcements.</p>
            </div>
          </div>
          <div className='du-email-bloc-content-card-right'>
            <label className="du-toggle-switch">
              <input type="checkbox" checked={toggles.generalUpdates} onChange={() => handleToggle('generalUpdates')} />
              <span className="du-slider round"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DUEmailBloc;
