import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  arrayUnion,
  increment,
  query,
  where
} from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import ProposeFeatureModal from "../DashboardUserComponents/UpvotePage/ProposeFeatureModal.js";
import AllFeaturesList from "../DashboardUserComponents/UpvotePage/AllFeaturesList.js";
import "../DashboardUserComponents/UpvotePage/FeatureUpvotePage.css";

function FeatureUpvotePage({ userAccount }) {
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showProposeModal, setShowProposeModal] = useState(false);

  // Category icons as React elements
  const categoryIcons = {
    UI: (
      <span className="material-symbols-outlined">frame_source</span>
    ),
    Dashboard: (
      <span className="material-symbols-outlined">dashboard</span>
    ),
    Builder: (
      <span className="material-symbols-outlined">construction</span>
    ),
    Integration: (
      <span className="material-symbols-outlined">thumbnail_bar</span>
    ),
    Performance: (
      <span className="material-symbols-outlined">web_asset</span>
    ),
  };

  // --- Load only approved features from Firestore ---
  const loadFeatures = async () => {
    setLoading(true);
    try {
      const featuresRef = collection(db, "features");
      // Query only the features that have been approved by an admin.
      const q = query(featuresRef, where("approved", "==", true));
      const snapshot = await getDocs(q);

      const fetched = snapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));
      setFeatures(fetched);
    } catch (error) {
      console.error("Error loading features:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadFeatures();
  }, [userAccount]);

  // --- Handle upvote logic ---
  const handleUpvote = async (featureId, upvoters = [], downvoters = []) => {
    if (!userAccount) {
      alert("You must be logged in to upvote a feature.");
      return;
    }

    const docRef = doc(db, "features", featureId);
    try {
      // If user has already upvoted, remove their upvote
      if (upvoters.includes(userAccount)) {
        await updateDoc(docRef, {
          upvoters: upvoters.filter(voter => voter !== userAccount),
          voteCount: increment(-1),
        });
      } 
      // If user has downvoted, remove downvote and add upvote
      else if (downvoters.includes(userAccount)) {
        await updateDoc(docRef, {
          downvoters: downvoters.filter(voter => voter !== userAccount),
          upvoters: arrayUnion(userAccount),
          voteCount: increment(2), // +2 because we're removing a downvote and adding an upvote
        });
      }
      // If user hasn't voted, add upvote
      else {
        await updateDoc(docRef, {
          upvoters: arrayUnion(userAccount),
          voteCount: increment(1),
        });
      }
      loadFeatures();
    } catch (error) {
      console.error("Error upvoting feature:", error);
    }
  };

  // --- Handle downvote logic ---
  const handleDownvote = async (featureId, upvoters = [], downvoters = []) => {
    if (!userAccount) {
      alert("You must be logged in to downvote a feature.");
      return;
    }

    const docRef = doc(db, "features", featureId);
    try {
      // If user has already downvoted, remove their downvote
      if (downvoters.includes(userAccount)) {
        await updateDoc(docRef, {
          downvoters: downvoters.filter(voter => voter !== userAccount),
          voteCount: increment(1),
        });
      }
      // If user has upvoted, remove upvote and add downvote
      else if (upvoters.includes(userAccount)) {
        await updateDoc(docRef, {
          upvoters: upvoters.filter(voter => voter !== userAccount),
          downvoters: arrayUnion(userAccount),
          voteCount: increment(-2), // -2 because we're removing an upvote and adding a downvote
        });
      }
      // If user hasn't voted, add downvote
      else {
        await updateDoc(docRef, {
          downvoters: arrayUnion(userAccount),
          voteCount: increment(-1),
        });
      }
      loadFeatures();
    } catch (error) {
      console.error("Error downvoting feature:", error);
    }
  };

  // --- After user submits a new feature, reload features & close modal ---
  const handleFeatureSubmitted = () => {
    setShowProposeModal(false);
    loadFeatures();
  };

  // --- Determine top features (e.g. last month's or overall top features) ---
  const now = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(now.getMonth() - 1);
  const lastMonthFeatures = features.filter((feat) => {
    if (!feat.createdAt || !feat.createdAt.toDate) return false;
    const createdDate = feat.createdAt.toDate();
    return createdDate >= oneMonthAgo;
  });
  const bestLastMonth = [...lastMonthFeatures]
    .sort((a, b) => (b.voteCount || 0) - (a.voteCount || 0))
    .slice(0, 3);
  const bestOverall = [...features]
    .sort((a, b) => (b.voteCount || 0) - (a.voteCount || 0))
    .slice(0, 3);
  const topFeatures = bestLastMonth.length > 0 ? bestLastMonth : bestOverall;

  return (
    <div className="feature-user-pages-content">
      {/* --- Hero / Banner Section --- */}
      <div className="feature-upvote-hero">
        <div className="feature-user-pages-content-bloc feature-user-pages-content-bloc-bg">
          <h1 className="feature-user-pages-content-h1">
            Help Shape the Future of dAppzy
          </h1>
          <p className="feature-user-pages-content-p">
            Vote for the features you need most and help us decide what to build next.
            Discover the most popular features from last month or suggest your own idea to get votes from the community.
          </p>
          <img
            className="du-main-page-img"
            src="./images/nas-img.png"
            alt="Hero banner"
          />
        </div>

        <div className="feature-upvote-hero-cta">
          <div className="feature-upvote-hero-cta-top">
            <h3 className="feature-upvote-hero-cta-title">Missing Something?</h3>
            <p className="feature-upvote-hero-cta-text">
              Have a great idea that's not listed? Share it with us and let the community vote!
            </p>
          </div>
          <button className="feature-upvote-hero-cta-btn" onClick={() => setShowProposeModal(true)}>
            Submit Your Idea
            <span className="material-symbols-outlined">send</span>
          </button>
        </div>
      </div>

      {/* Optionally, include TopFeaturesSection */}
      {/* <TopFeaturesSection topFeatures={topFeatures} categoryIcons={categoryIcons} /> */}

      {/* --- Full Feature List Section --- */}
      <AllFeaturesList
        loading={loading}
        features={features}
        userAccount={userAccount}
        categoryIcons={categoryIcons}
        onUpvote={handleUpvote}
        onDownvote={handleDownvote}
      />

      {/* --- Propose Feature Modal --- */}
      {showProposeModal && (
        <ProposeFeatureModal
          userAccount={userAccount}
          onClose={() => setShowProposeModal(false)}
          onFeatureSubmitted={handleFeatureSubmitted}
        />
      )}
    </div>
  );
}

export default FeatureUpvotePage;
