// src/App.js
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import Resources from "./pages/Resources";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import Terms from "./pages/legal/Terms";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import GetStartedMobile from "./components/website/GetStartedMobile";
import Agency from "./pages/Agency";
import Builder from "./pages/Builder";
import Academy from "./pages/Academy";
import About from "./pages/About";
import WaitlistPage from "./pages/WaitlistPage";
import ConnectAdmin from "./components/dashboardAdmin/Admin/ConnectAdmin";
import DashboardAdmin from "./components/dashboardAdmin/Admin/DashboardAdmin";
import WaitlistManagement from "./components/dashboardAdmin/Admin/WaitlistManagement";
import PopupWallet from "./pages/PopupWallet";
import DashboardUser from "./pages/DashboardUser";
import LegalNotice from "./pages/legal/LegalNotice";

export default function App() {
  const [isAdminSubdomain, setIsAdminSubdomain] = useState(false);
  const [isDashboardSubdomain, setIsDashboardSubdomain] = useState(false);
  const [walletId, setWalletId] = useState(localStorage.getItem("userAccount"));

  useEffect(() => {
    const subdomain = window.location.hostname.split(".")[0];
    if (subdomain === "admin") {
      setIsAdminSubdomain(true);
    }
    if (subdomain === "dashboard") {
      setIsDashboardSubdomain(true);
    }
  }, []);

  return (
    <div>
      <Router>
        {isAdminSubdomain ? (
          <Routes>
            <Route
              path="/"
              element={<ConnectAdmin walletId={walletId} />}
            />
            <Route path="/dashboard-admin" element={<DashboardAdmin />} />
            <Route
              path="/dashboard-admin/waitlist-management"
              element={<WaitlistManagement />}
            />
            <Route path="*" element={<NoPage />} />
          </Routes>
        ) : isDashboardSubdomain ? (
          <Routes>
            {/* Dashboard subdomain routes */}
            <Route path="/" element={<DashboardUser />} />
            <Route path="/login" element={<PopupWallet />} />
            {/* Add additional dashboard-specific routes if needed */}
            <Route path="*" element={<NoPage />} />
          </Routes>
        ) : (
          <Routes>
            <Route index element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/waitlist" element={<WaitlistPage />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/get-started-mobile" element={<GetStartedMobile />} />
            <Route path="/legal-notice" element={<LegalNotice />} />
            <Route path="/3s-agency" element={<Agency />} />
            <Route path="/3s-builder" element={<Builder />} />
            <Route path="/3s-academy" element={<Academy />} />
            <Route path="/about" element={<About />} />
            <Route path="/admin" element={<ConnectAdmin />} />
            <Route path="/dashboard-admin" element={<DashboardAdmin />} />
            <Route
              path="/dashboard-admin/waitlist-management"
              element={<WaitlistManagement />}
            />
            <Route path="/dashboard" element={<DashboardUser />} />
            <Route path="/login" element={<PopupWallet />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
        )}
      </Router>
    </div>
  );
}
