// src/index.js

// 1. Import all modules at the top
import React from 'react';
import ReactDOM from 'react-dom';
import { Buffer } from 'buffer';

import App from './App';

// 2. Now do any setup logic after all imports
if (!window.Buffer) {
  window.Buffer = Buffer;
}

// 3. Then render your app
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
