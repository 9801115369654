import './DULeftBar.css';

function DULeftBar({ onLogout, onNavigate, currentPage }) {

    const getButtonClass = (page) => {
        return `du-leftbar-content-item ${currentPage === page ? 'active' : ''}`;
    };

    return (
        <div className='du-leftbar-wrapper'>
            <img className='du-leftbar-logo' src="https://firebasestorage.googleapis.com/v0/b/third--space.appspot.com/o/ImageWebSite%2FlogoNewSansName.png?alt=media&token=7809b8e0-a6b6-43ac-9d1d-5c439328254b" alt="Logo"></img>
            <div className='du-leftbar-content'>
                <button onClick={() => onNavigate("main")} className={getButtonClass("main")}>
                    <i class="bi bi-grid"></i>
                </button>
                {/* <button onClick={() => onNavigate("profile")} className={getButtonClass("profile")}>
                    <i class="bi bi-person"></i>
                </button>
                <button onClick={() => onNavigate("billing")} className={getButtonClass("billing")}>
                    <i class="bi bi-speedometer2"></i>
                </button> */}
                <button onClick={() => onNavigate("feature-upvote")} className={getButtonClass("feature-upvote")}>
                    <i class="bi bi-capslock"></i>
                </button>
            </div>
            <button onClick={onLogout} className='du-leftbar-content-item'>
                <span className="material-symbols-outlined">logout</span>
            </button>
        </div>
    );
}

export default DULeftBar;