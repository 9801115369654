// src/components/DashboardUser/CreateProjectPopup.js
import React, { useState } from "react";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../../firebaseConfig"; // adjust the path if needed
import "./CreateProjectPopup.css";

function CreateProjectPopup({ userAccount, onClose, onProjectCreated }) {
  const [projectName, setProjectName] = useState("");

  const isCreateDisabled = !projectName.trim(); // true if empty

  const handleCreateClick = async () => {
    if (!projectName.trim()) {
      alert("Please enter a project name.");
      return;
    }
    try {
      // Create a new doc in subcollection: 'projects/{userAccount}/ProjectRef'
      const collRef = collection(db, "projects", userAccount, "ProjectRef");
      const docRef = await addDoc(collRef, {
        userId: userAccount,
        createdAt: serverTimestamp(),
        websiteSettings: {
          siteTitle: projectName.trim(),
          // ...any other default fields
        },
      });

      // Pass the newly created doc ID back up so we can redirect or reload
      onProjectCreated(docRef.id);
    } catch (error) {
      console.error("Error creating new project:", error);
      alert("Error creating project. Please try again later.");
    }
  };

  return (
    <div className="create-project-popup-overlay">
      <div className="create-project-popup-content">
        <h2>Create a New Project</h2>
        <p>Give your project a name to get started.</p>
        <input
          type="text"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          placeholder="(Project Name)"
        />
        <div className="create-project-popup-buttons">
          <button onClick={onClose} className="cancel-button">
            Cancel
          </button>
          <button
            onClick={handleCreateClick}
            className="create-button"
            disabled={isCreateDisabled}
          >
            Create Project
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateProjectPopup;
