// This is the updated version of your Terms of Use page with the full, enriched content and original styling intact.
import React, { useState, useEffect } from "react";
import Footer from "../../components/website/GenericComponents/Footer";
import './legal.css';
import NavBar from "../../components/website/GenericComponents/NavBar";
import { db, getDocs, collection } from "../../firebaseConfig";

export default function Terms() {
  const [hasStepData, setHasStepData] = useState(false);
  const [hasWalletData, setHasWalletData] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const checkWalletData = async () => {
    const userAccount = sessionStorage.getItem("userAccount");
    if (userAccount) {
      const docRef = collection(db, 'projects', userAccount, 'projectData');
      const docSnap = await getDocs(docRef);
      if (!docSnap.empty) {
        setHasWalletData(true);
        let userData = [];
        docSnap.forEach((doc) => {
          userData.push(doc.data());
        });
        if (userData.length > 0) {
          setHasStepData(true);
        }
      } else {
        setHasWalletData(false);
      }
      setAccounts([userAccount]);
    }
  };

  return (
    <>
      <NavBar
        checkWalletData={checkWalletData}
        hasWalletData={hasWalletData}
        accounts={accounts}
        setAccounts={setAccounts}
      />
       <div className="legal-text terms-of-use">
      <h1 className="legal-title">Terms of Use</h1>
      <p className="legal-updated">Last updated: March 24, 2025</p>
      
      <p>Welcome to DAppzy, a no-code platform operated by Third Space SAS, allowing users to build and deploy decentralized applications (dApps) using Web3 technologies. These Terms of Use (“Terms”) govern your access to and use of our platform. By accessing <a href="http://dappzy.io/">dAppzy.io</a>, you agree to comply with these Terms.</p>
      
      <h2>1. Who We Are</h2>
      <p><strong>Company:</strong> Third Space, SAS</p>
      <p><strong>Address:</strong> 198D Route de Neuville, 01390 Saint André de Corcy, France</p>
      <p><strong>Registration Number:</strong> 930 682 703 00010</p>
      <p><strong>Email:</strong> <a href="mailto:contact@dappzy.io">contact@dappzy.io</a></p>
      
      <h2>2. Platform Access</h2>
      <p>DAppzy is open to all users, including individuals, entrepreneurs, and organizations. No age restriction is enforced; however, you must have the legal capacity to enter into these Terms in your country of residence.</p>
      <p>You can access the platform via:</p>
      <ul>
        <li><strong>Wallet connection</strong> (e.g., MetaMask, Phantom, Freighter, Unstoppable Domains)</li>
        <li><strong>Magic link email authentication</strong></li>
      </ul>
      <p>A personal dashboard is generated once authenticated, where your projects and settings are managed.</p>
      
      <h2>3. Project Hosting & Data</h2>
      <p>All user-created dApps are:</p>
      <ul>
        <li>Stored on <strong>Firebase</strong> and/or <strong>IPFS</strong></li>
        <li>Associated with your wallet address or email identity</li>
      </ul>
      <p>You are responsible for the security of your wallet and credentials.</p>
      
      <h2>4. Plans & Subscription</h2>
      <p>DAppzy is offered under a <strong>freemium model</strong> with optional paid subscription tiers (monthly or yearly).</p>
      <p>Because payments are made through decentralized wallets, <strong>automatic renewals are not available</strong>.</p>
      <p>You will receive an email <strong>72 hours before the expiration</strong> of your plan. If you do not renew manually:</p>
      <ul>
        <li>You will enter a 1-month grace period</li>
        <li>After that, your account may be restricted or suspended</li>
      </ul>
      <p>No free trials or refunds are provided.</p>
      
      <h2>5. Ownership & Licensing</h2>
      <ul>
        <li>You retain <strong>100% ownership and intellectual property rights</strong> over your created projects.</li>
        <li>You may use all templates, page sections, and components within DAppzy to build your dApps.</li>
        <li>Redistribution or resale of DAppzy's builder or template elements outside the platform is not permitted.</li>
        <li>All backend code, UI elements, and infrastructure remain the sole property of Third Space.</li>
      </ul>
      
      <h2>6. Prohibited Use</h2>
      <p>You agree not to use the platform for:</p>
      <ul>
        <li>Fraudulent or illegal activity, including but not limited to phishing, scams, or laundering</li>
        <li>Deploying projects that violate applicable laws or regulations in your jurisdiction</li>
      </ul>
      <p>Projects suspected of violating these terms may be:</p>
      <ul>
        <li>Flagged with a warning banner for visitors</li>
        <li>Notified to the owner via email</li>
        <li>Subject to review or suspension</li>
      </ul>
      <p><strong>Appeal period:</strong> 30 calendar days from notice via <a href="mailto:contact@dappzy.io">contact@dappzy.io</a></p>
      
      <h2>7. Public Deployment</h2>
      <p>Users may deploy projects:</p>
      <ul>
        <li>To <strong>IPFS</strong></li>
        <li>Under a <strong>custom domain</strong></li>
      </ul>
      <p>You are solely responsible for the legality and safety of the content you publish.</p>
      
      <h2>8. Support & Reliability</h2>
      <p>While we strive to offer a stable and secure platform, DAppzy is provided “as is.”</p>
      <p>We do not guarantee uninterrupted service or that projects will always be accessible.</p>
      <p>In case of bugs or technical issues, our team will take reasonable steps to help you resolve them.</p>
      
      <h2>9. Suspension & Termination</h2>
      <p>We reserve the right to suspend or terminate your access if:</p>
      <ul>
        <li>You violate these Terms</li>
        <li>Your project poses legal, ethical, or reputational risks</li>
      </ul>
      <p>Where applicable, we will notify you and provide 30 days to respond or remediate.</p>
      
      <h2>10. Limitation of Liability</h2>
      <p>To the extent permitted by law:</p>
      <ul>
        <li>DAppzy and Third Space are not liable for any loss of data, project corruption, wallet access issues, or third-party damage.</li>
        <li>You accept full responsibility for your usage of the platform.</li>
      </ul>
      
      <h2>11. Governing Law & Dispute Resolution</h2>
      <p>These Terms are governed by:</p>
      <ul>
        <li><strong>French law</strong> (for users in the EU)</li>
        <li><strong>Californian law</strong> (for users in the United States)</li>
      </ul>
      <p>Before initiating legal proceedings, both parties agree to attempt an <strong>amicable resolution</strong> within 30 days via email.</p>
      
      <h2>12. Changes to Terms</h2>
      <p>We may revise these Terms occasionally. If so, we will update the date at the top of this document.</p>
      <p>By continuing to use DAppzy after changes are posted, you agree to the updated Terms.</p>
      
      <h2>13. Other Legal Documents</h2>
      <p>These Terms incorporate and are supplemented by the following:</p>
      <ul>
        <li><a href="/privacy-policy">Privacy Policy</a></li>
        <li><a href="/legal-notice">Legal Notice</a></li>
      </ul>
      <p>If you have any questions, contact us at <a href="mailto:contact@dappzy.io">contact@dappzy.io</a></p>
    </div>
      <Footer />
    </>
  );
}
