import React, { useState, useEffect } from "react";
import Footer from "../../components/website/GenericComponents/Footer";
import NavBar from "../../components/website/GenericComponents/NavBar";
import { db, getDocs, collection } from "../../firebaseConfig";
import './legal.css';

export default function LegalNotice() {
    const [hasStepData, setHasStepData] = useState(false); // State to track if stepData is available
    const [hasWalletData, setHasWalletData] = useState(false);
    const [accounts, setAccounts] = useState([]);
    console.log(accounts);

    const checkWalletData = async () => {
        const userAccount = sessionStorage.getItem("userAccount");
        if (userAccount) {
            const docRef = collection(db, "projects", userAccount, "projectData");
            const docSnap = await getDocs(docRef);
            if (!docSnap.empty) {
                // Check if the snapshot is not empty
                setHasWalletData(true);
                let userData = [];
                docSnap.forEach((doc) => {
                    userData.push(doc.data());
                });
                console.log(userData);
                if (userData.length > 0) {
                    // Check if userData is present
                    setHasStepData(true);
                }
            } else {
                setHasWalletData(false);
            }
            setAccounts([userAccount]);
        }
    };

    return (
        <>
            <NavBar
                checkWalletData={checkWalletData}
                hasWalletData={hasWalletData}
                accounts={accounts}
                setAccounts={setAccounts}
            />
            <div className="privacy-policy legal-text">
                <h1 className="legal-title">Legal Notice</h1>
                <p className="legal-updated">Last updated: March 24, 2025</p>

                <p><bold>Company Name:</bold> Third Space</p>
                <p><bold>Legal Form: </bold> SAS (Société par Actions Simplifiée)</p>
                <p><bold>Registered Address:</bold> 198D Route de Neuville, 01390 Saint André de Corcy, France</p>
                <p><bold>Registration Number: </bold> 930 682 703 00010</p>
                <p><bold>VAT Number: </bold> FR24930682703</p>
                <p><bold>Director of Publication:</bold> Pauline Mila-Alonso (CEO)</p>
                <p><bold>Email: </bold> contact@dappzy.io</p>
                <p><bold>Hosting Provider: </bold> Firebase (Google LLC)</p>
                <p><bold>Hosting Address: </bold> 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA</p>
                <p><bold>Hosting Contact: </bold> https://firebase.google.com/support</p>
                <p>For any legal inquiries, please contact us at contact@dappzy.io.</p>


            </div>∑


            <Footer />
        </>
    );
}
