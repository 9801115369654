import React, { useState } from "react";
import "../Unused/login/PopupWallet.css";
import { db, doc, getDoc, setDoc } from '../../firebaseConfig';
import UAuth from '@uauth/js';
import { requestAccess, signMessage } from "@stellar/freighter-api"; // Freighter API methods

function WalletConnection({
  saveLoginEvent,
  logEvent,
  checkWalletData,
  checkForWallet,
  onClose,
  walletAvailable,
  onUserLogin,
  customErrorMessage
}) {
  const [phantomInitiated, setPhantomInitiated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // ----- Common Processing -----
  const processLogin = (userId, walletType) => {
    if (typeof onUserLogin === "function") {
      onUserLogin(userId);
      sessionStorage.setItem("isLoggedIn", "true");
      sessionStorage.setItem("userAccount", userId);
      checkWalletData(userId);
      saveLoginEvent(userId, walletType);
    } else {
      console.error("onUserLogin is not a function");
    }
  };

  // ----- Ethereum (Metamask) -----
  const authenticateWithEthereum = async (walletId) => {
    try {
      const message = "Please sign this message to confirm your identity.";
      await window.ethereum.request({
        method: "personal_sign",
        params: [message, walletId],
      });
      processLogin(walletId, 'Ethereum');
    } catch (error) {
      console.error("Error during Ethereum authentication:", error);
      setErrorMessage("Ethereum authentication failed. Please try again");
    }
  };

  const handleLoginWithMetamask = async () => {
    logEvent('Click', 'Metamask Login Attempt');
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        const account = accounts[0];

        // Optionally store in Firestore
        const walletRef = doc(db, 'wallets', account);
        const walletSnap = await getDoc(walletRef);
        const timestamp = new Date().toISOString();

        if (!walletSnap.exists()) {
          await setDoc(walletRef, { walletId: account, lastLogin: timestamp, walletType: 'Ethereum' });
          console.log("Wallet ID saved to Firestore:", account);
        } else {
          console.log("Wallet data retrieved:", walletSnap.data());
        }

        // Now sign and process
        await authenticateWithEthereum(account);
      } catch (error) {
        console.error("Error with MetaMask login:", error);
        setErrorMessage('MetaMask authentication failed. Please try again');
      }
    } else {
      setErrorMessage('MetaMask is not installed. Install it and try again');
    }
  };

  // ----- Solana (Phantom) -----
  const authenticateWithSolana = async (publicKey) => {
    try {
      const message = new TextEncoder().encode("Please sign this message to confirm your identity.");
      await window.solana.signMessage(message);
      processLogin(publicKey, 'Solana');
    } catch (error) {
      console.error("Error during Solana authentication:", error);
      setErrorMessage("Solana authentication failed. Please try again");
    }
  };

  const handleLoginWithPhantom = async () => {
    logEvent('Click', 'Phantom Login Attempt');
    if ("solana" in window && window.solana.isPhantom) {
      try {
        const response = await window.solana.connect();
        const publicKey = response.publicKey.toString();

        // Optionally store in Firestore
        const walletRef = doc(db, 'wallets', publicKey);
        const walletSnap = await getDoc(walletRef);
        const timestamp = new Date().toISOString();

        if (!walletSnap.exists()) {
          await setDoc(walletRef, { walletId: publicKey, lastLogin: timestamp, walletType: 'Solana' });
          console.log("Wallet ID saved to Firestore:", publicKey);
        } else {
          console.log("Wallet data retrieved:", walletSnap.data());
        }

        // Now sign and process
        await authenticateWithSolana(publicKey);
      } catch (error) {
        console.error("Error connecting to Phantom:", error);
        setErrorMessage('Phantom authentication failed. Please try again');
      }
    } else {
      if (phantomInitiated) {
        checkForWallet();
        if (walletAvailable) {
          setErrorMessage("Phantom wallet is now available. Please try connecting again");
        } else {
          setErrorMessage('Phantom wallet is not available. Please refresh the page and try again');
        }
      } else {
        setErrorMessage('Phantom wallet installation isn\'t completed. Please install Phantom.');
        setPhantomInitiated(true);
      }
    }
  };

  // ----- Unstoppable Domains -----
  const authenticateWithUnstoppable = async (authorization) => {
    try {
      const userId = authorization.idToken.sub;
      if (!userId) throw new Error("Username is undefined in the authorization object");
      processLogin(userId, 'Unstoppable');
    } catch (error) {
      console.error("Error during Unstoppable authentication:", error);
      setErrorMessage('Unstoppable authentication failed. Please try again');
    }
  };

  const handleLoginWithUnstoppable = async () => {
    logEvent('Click', 'Unstoppable Login Attempt');
    const uauth = new UAuth({
      clientID: "65f44ad3-b7ad-4e87-b782-9654d7257a4c",
      redirectUri: "http://localhost:3000",
      scope: "openid wallet"
    });

    try {
      const authorization = await uauth.loginWithPopup();
      authenticateWithUnstoppable(authorization);
    } catch (error) {
      console.error("Error with Unstoppable login:", error);
      setErrorMessage('Unstoppable login failed. Please try again');
    }
  };

  // ----- Freighter (Stellar) -----
  const handleLoginWithFreighter = async () => {
    try {
      // Request access to Freighter (this will prompt the user if not already allowed)
      const accessObj = await requestAccess();
      if (accessObj.error) {
        throw new Error(accessObj.error.message);
      }
      const publicKey = accessObj.address;
      if (!publicKey) {
        throw new Error("Unable to retrieve public key from Freighter.");
      }

      // Ask the user to sign a message to mimic other wallet flows
      const message = "Please sign this message to confirm your identity.";
      const signResult = await signMessage(message, { address: publicKey });
      if (signResult.error) {
        throw new Error(signResult.error);
      }
      const signature = signResult.signedMessage;
      console.log("Freighter signature:", signature);

      processLogin(publicKey, "Freighter");
    } catch (error) {
      console.error("Error with Freighter login:", error);
      setErrorMessage("Freighter authentication failed. Please try again");
    }
  };

  return (
    <div className="wallet-list">
      <h3>Connect your wallet</h3>
      <p>Choose your preferred wallet to log in and continue exploring</p>
      {customErrorMessage && <div className="error-message">{customErrorMessage}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}

      {/* Phantom */}
      <button
        id="phantom"
        className="wallet-btn ga-wallet-btn-phantom"
        onClick={handleLoginWithPhantom}
      >
        <div className="wallet-icon-wrapper">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/third--space.appspot.com/o/ImageWebSite%2FPopup%2Fphantom-logo.png?alt=media"
            alt=""
          />
        </div>
        <span className="wallet-label">Phantom</span>
      </button>

      {/* Metamask */}
      <button
        id="metamask"
        className="wallet-btn ga-wallet-btn-metamask"
        onClick={handleLoginWithMetamask}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/third--space.appspot.com/o/ImageWebSite%2FPopup%2Fmetamask-logo.png?alt=media"
          alt=""
        />
        Metamask
      </button>

      {/* Unstoppable */}
      <button
        id="unstoppable"
        className="wallet-btn ga-wallet-btn-ud"
        onClick={handleLoginWithUnstoppable}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/third--space.appspot.com/o/ImageWebSite%2FPopup%2Funstoppablelogo.png?alt=media"
          alt=""
        />
        Unstoppable
      </button>

      {/* Freighter */}
      <button
        id="freighter"
        className="wallet-btn ga-wallet-btn-freighter"
        onClick={handleLoginWithFreighter}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/third--space.appspot.com/o/ImageWebSite%2FPopup%2Fstellar_logo.png?alt=media"
          alt="freighter-icon"
          style={{borderRadius:"0"}}
        />
        Freighter
      </button>
    </div>
  );
}

export default WalletConnection;
