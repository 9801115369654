import React, { useState, useEffect, useRef } from "react";
import {
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
  collection,
  query,
  where,
  getDocs
} from "firebase/firestore";
import { db } from "../../../../firebaseConfig";
import "./DUProfileRecap.css";

function DUProfileRecap({ userAccount }) {
  const [email, setEmail] = useState("");
  const [photoURL, setPhotoURL] = useState("./img/logoNew.png");
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isPioneer, setIsPioneer] = useState(false); // Flag to display Pioneer label

  const inputRef = useRef(null);

  // Fetch user data from Firestore "users" collection (including any stored pioneer status)
  useEffect(() => {
    const fetchUserData = async () => {
      if (!userAccount) return;
      try {
        const userRef = doc(db, "users", userAccount);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const data = userSnap.data();
          setEmail(data.profile?.email || "");
          setPhotoURL(data.profile?.photoURL || "");
          // Retrieve stored pioneer status if available
          setIsPioneer(data.profile?.isPioneer || false);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
  }, [userAccount]);

  // Check if the user's normalized email exists in the waitlist collection
  useEffect(() => {
    if (!email || !userAccount) return;
    const normalizedEmail = email.trim();
    const checkWaitlist = async () => {
      try {
        const waitlistCollectionRef = collection(db, "waitlist");
        // Range query to find documents whose email starts with the normalized email
        const q = query(
          waitlistCollectionRef,
          where("email", ">=", normalizedEmail),
          where("email", "<=", normalizedEmail + "\uf8ff")
        );
        const querySnapshot = await getDocs(q);
        let found = false;
        querySnapshot.forEach((docSnap) => {
          const storedEmail = docSnap.data().email;
          if (storedEmail && storedEmail.trim() === normalizedEmail) {
            found = true;
          }
        });
        setIsPioneer(found);
        // If a match is found, update the user's document with the pioneer status
        if (found) {
          const userRef = doc(db, "users", userAccount);
          await setDoc(
            userRef,
            {
              profile: { isPioneer: true },
              lastUpdated: serverTimestamp(),
            },
            { merge: true }
          );
        }
      } catch (error) {
        console.error("Error checking waitlist:", error);
      }
    };
    checkWaitlist();
  }, [email, userAccount]);

  // Focus the email input when editing starts
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  // Save updated email to Firestore and exit edit mode
  const handleSaveEmail = async () => {
    if (!userAccount) return;
    try {
      const userRef = doc(db, "users", userAccount);
      await setDoc(
        userRef,
        {
          profile: { email },
          lastUpdated: serverTimestamp(),
        },
        { merge: true }
      );
    } catch (error) {
      console.error("Error updating email:", error);
    }
    setIsEditing(false);
  };

  // Switch to edit mode when the email is clicked
  const handleEmailClick = () => {
    setIsEditing(true);
  };

  // Mask wallet address for display
  function maskWalletAddress(address) {
    if (!address || address.length < 8) return address;
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  }

  if (loading) {
    return <p>Loading profile...</p>;
  }

  return (
    <div className="dashboard-user-pages-content-bloc du-pages-content-bloc-profile du-height-200">
      <div className="dashboard-user-pages-content-bloc-top">
        <p className="dashboard-user-pages-content-bloc-top-title">Profile</p>
        {/* Render the Pioneer label if the user is in the waitlist */}
        {isPioneer && (
          <div className="dashboard-user-pages-content-bloc-top-label">
            <i className="bi bi-award"></i>
            <p className="dashboard-user-pages-content-bloc-top-label-text">Pioneer</p>
          </div>
        )}
      </div>

      <div className="du-profile-recap-content">
        <div className="du-profile-recap-content-right">
          <div className="du-profile-recap-content-right-box">
            <h3 className="du-profile-recap-content-right-box-title">Wallet Address</h3>
            <p className="du-profile-recap-content-right-box-text">
              {userAccount ? maskWalletAddress(userAccount) : "No wallet connected"}
              <span
                className="material-symbols-outlined"
                onClick={() => {
                  if (userAccount) {
                    navigator.clipboard.writeText(userAccount);
                    console.log("Wallet address copied!");
                  }
                }}
                style={{ cursor: "pointer", marginLeft: "8px" }}
              >
                content_copy
              </span>
            </p>
          </div>

          <div className="du-profile-recap-content-right-box">
            <h3 className="du-profile-recap-content-right-box-title">Email Address</h3>
            {isEditing ? (
              <div className="email-edit-container">
                <input
                  type="email"
                  value={email}
                  ref={inputRef}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleSaveEmail();
                  }}
                  onBlur={handleSaveEmail}
                />
              </div>
            ) : (
              <p
                className="du-profile-recap-content-right-box-text"
                onClick={handleEmailClick}
                style={{ cursor: "pointer" }}
              >
                {email || "No email provided"}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DUProfileRecap;
