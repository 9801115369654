import React, { useState } from "react";
import "./DeletePopup.css";

function boldKeyword(text, keyword) {
  if (!text.includes(keyword)) {
    return text;
  }
  const [before, after] = text.split(keyword, 2);
  return (
    <>
      {before}
      <strong>{keyword}</strong>
      {after}
    </>
  );
}

function DeletePopup({
  title,
  warningMessage,
  confirmLabel,
  confirmPlaceholder,
  onConfirm,
  onCancel,
  onClose,
  deleteButtonText,
  cancelButtonText,
  isProject = true,
}) {
  const [typedValue, setTypedValue] = useState("");

  // Match typedValue exactly to confirmLabel
  const isMatch = typedValue.trim() === confirmLabel.trim();

  const handleConfirmClick = () => {
    if (isMatch && onConfirm) {
      onConfirm();
    }
  };

  return (
    <div className="delete-popup-overlay" onClick={onCancel}>
      <div
        className="delete-popup-container"
        onClick={(e) => e.stopPropagation()}
      >
        <button className="close-button" onClick={onClose || onCancel}>
          &times;
        </button>

        <h2>{title}</h2>

        <div className="red-warning-box">
        <i class="bi bi-exclamation-triangle-fill"></i>
          <p>
          
            <strong>{warningMessage}</strong>
          </p>
        </div>

      
        <p className="delete-description">
        This action <strong>CANNOT</strong> be undone. All files, assets, and associated data will be deleted and cannot be recovered. Please make sure you have backed up any important information before proceeding.
        </p>

        <label className="delete-popup-label">
          To confirm, please type{" "}
          {isProject ? "the project’s name" : "your account’s email"} below:
        </label>

        <input
          type="text"
          value={typedValue}
          placeholder={confirmPlaceholder}
          onChange={(e) => setTypedValue(e.target.value)}
        />

        <div className="delete-popup-buttons">
          <button className="cancel-button" onClick={onCancel}>
            {cancelButtonText}
          </button>
          <button
            className="delete-button"
            onClick={handleConfirmClick}
            disabled={!isMatch}
          >
            {deleteButtonText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeletePopup;
