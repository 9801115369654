import React, { useState, useEffect } from "react";
import Footer from "../../components/website/GenericComponents/Footer";
import NavBar from "../../components/website/GenericComponents/NavBar";
import { db, getDocs, collection } from "../../firebaseConfig";
import './legal.css';

export default function PrivacyPolicy() {
  const [hasStepData, setHasStepData] = useState(false); // State to track if stepData is available
  const [hasWalletData, setHasWalletData] = useState(false);
  const [accounts, setAccounts] = useState([]);
  console.log(accounts);

  const checkWalletData = async () => {
    const userAccount = sessionStorage.getItem("userAccount");
    if (userAccount) {
      const docRef = collection(db, "projects", userAccount, "projectData");
      const docSnap = await getDocs(docRef);
      if (!docSnap.empty) {
        // Check if the snapshot is not empty
        setHasWalletData(true);
        let userData = [];
        docSnap.forEach((doc) => {
          userData.push(doc.data());
        });
        console.log(userData);
        if (userData.length > 0) {
          // Check if userData is present
          setHasStepData(true);
        }
      } else {
        setHasWalletData(false);
      }
      setAccounts([userAccount]);
    }
  };

  return (
    <>
      <NavBar
        checkWalletData={checkWalletData}
        hasWalletData={hasWalletData}
        accounts={accounts}
        setAccounts={setAccounts}
      />

      <div className="privacy-policy legal-text">
        <h1 className="legal-title">Privacy Policy</h1>
        <p className="legal-updated">Last updated: March 24, 2025</p>

        ```
        <p>At DAppzy, we take your privacy seriously. This Privacy Policy outlines how we collect, use, and protect your personal data in compliance with the EU General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA).</p>

        <h2 className="legal-section-title">1. Data We Collect</h2>
        <ul>
          <li><strong>Email address</strong> (if provided via magic link)</li>
          <li><strong>Wallet address</strong> (for authentication and project access)</li>
          <li><strong>Usage data</strong> (via Google Analytics)</li>
        </ul>
        <p>No sensitive personal data is collected. Wallets are pseudonymous but may be linked to your email when both are used.</p>

        <h2 className="legal-section-title">2. Legal Basis for Processing (EU)</h2>
        <p>We process your personal data on the basis of:</p>
        <ul>
          <li>Your consent</li>
          <li>Contractual necessity</li>
          <li>Legitimate interest (e.g., security, analytics)</li>
        </ul>

        <h2 className="legal-section-title">3. How We Use Your Data</h2>
        <ul>
          <li>To enable platform access via wallet or email login</li>
          <li>To send service-related communications</li>
          <li>To improve user experience and platform performance</li>
          <li>To comply with legal obligations</li>
        </ul>

        <h2 className="legal-section-title">4. Data Sharing</h2>
        <p>We do not sell your personal data. Your information may be shared with trusted processors such as:</p>
        <ul>
          <li>Firebase (hosting)</li>
          <li>Google Analytics (analytics)</li>
        </ul>

        <h2 className="legal-section-title">5. Data Retention</h2>
        <p>We retain your data as long as your account is active or as needed to provide services. You can request deletion at any time.</p>

        <h2 className="legal-section-title">6. Your Rights</h2>
        <p>As a user, you have the right to:</p>
        <ul>
          <li>Access your data</li>
          <li>Correct or delete your data</li>
          <li>Object to or restrict processing</li>
          <li>Withdraw your consent</li>
          <li>Request data portability</li>
        </ul>
        <p>To exercise these rights, email us at <a href="mailto:contact@dappzy.io">contact@dappzy.io</a>.</p>

        <h2 className="legal-section-title">7. Cookies & Tracking</h2>
        <p>We use cookies and Google Analytics to analyze traffic and improve the platform. You can opt out or configure preferences through your browser settings.</p>

        <h2 className="legal-section-title">8. Children’s Privacy</h2>
        <p>The platform is not directed at children under 13 (COPPA) or under 16 (GDPR). We do not knowingly collect personal data from minors.</p>

        <h2 className="legal-section-title">9. International Transfers</h2>
        <p>Some of our service providers are located outside the EU. Data is transferred with appropriate safeguards (e.g., SCCs or equivalent).</p>

        <h2 className="legal-section-title">10. Contact Information</h2>
        <p>For questions about this Privacy Policy or your data, please contact our Data Protection Officer:</p>
        <p><strong>Pauline Mila-Alonso</strong></p>
        <p>Email: <a href="mailto:pmilaalonso@dappzy.io">pmilaalonso@dappzy.io</a></p>
      </div>

      <Footer />
    </>
  );
}

