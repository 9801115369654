import React, { useState } from "react";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db, storage } from "../../../../firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "./ProposeFeatureModal.css";

function ProposeFeatureModal({ userAccount, onClose, onFeatureSubmitted }) {
  const [featureName, setFeatureName] = useState("");
  const [featureDescription, setFeatureDescription] = useState("");
  const [featureCategory, setFeatureCategory] = useState("");
  const [uploadFile, setUploadFile] = useState(null); // the image file the user chooses
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Category icons mapping
  const categoryIcons = {
    UI: <span className="material-symbols-outlined">palette</span>,
    Dashboard: <span className="material-symbols-outlined">dashboard</span>,
    Builder: <span className="material-symbols-outlined">construction</span>,
    Integration: <span className="material-symbols-outlined">extension</span>,
    Performance: <span className="material-symbols-outlined">speed</span>,
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setUploadFile(e.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    // Basic validation
    if (!featureName.trim() || !featureDescription.trim() || !featureCategory) {
      setErrorMessage("Please fill out required fields: name, description, category.");
      return;
    }
    setIsSubmitting(true);

    try {
      let imageURL = "";

      // 1) If the user selected a file, upload it to Firebase Storage
      if (uploadFile) {
        const storageRef = ref(storage, `featureImages/${uploadFile.name}`);
        await uploadBytes(storageRef, uploadFile);
        imageURL = await getDownloadURL(storageRef);
      }

      // 2) Create a new feature object with an "approved" flag set to false.
      const newFeature = {
        title: featureName.trim(),
        description: featureDescription.trim(),
        category: featureCategory,
        createdBy: userAccount,
        voteCount: 0,
        upvoters: [],
        createdAt: serverTimestamp(),
        imageURL: imageURL,
        approved: false, // New property – admin must validate this feature before it is shown
      };

      // 3) Add a new document to Firestore "features" collection.
      const featuresColl = collection(db, "features");
      await addDoc(featuresColl, newFeature);

      // 4) Notify the parent that the feature has been submitted.
      onFeatureSubmitted();
    } catch (error) {
      console.error("Error submitting new feature:", error);
      setErrorMessage("Could not submit feature. Please try again later.");
    }

    setIsSubmitting(false);
  };

  return (
    <div className="propose-feature-overlay" onClick={onClose}>
      <div className="propose-feature-content" onClick={(e) => e.stopPropagation()}>
        <button className="propose-feature-close" onClick={onClose}>&times;</button>
        <h2>Propose a New Feature</h2>
        <p>Share your idea for a new feature. The more details, the better!</p>

        <label>
          Feature Name <span style={{ color: "var(--purple, #5C4EFA)" }}>*</span>
        </label>
        <input
          type="text"
          value={featureName}
          onChange={(e) => setFeatureName(e.target.value)}
          placeholder="Enter feature name..."
        />

        <label>
          Feature Description <span style={{ color: "var(--purple, #5C4EFA)" }}>*</span>
        </label>
        <textarea
          value={featureDescription}
          onChange={(e) => setFeatureDescription(e.target.value)}
          placeholder="Describe the feature and why it would be useful..."
        />

        <label>
          Feature Category <span style={{ color: "var(--purple, #5C4EFA)" }}>*</span>
        </label>
        <select
          value={featureCategory}
          onChange={(e) => setFeatureCategory(e.target.value)}
          className="category-select"
        >
          <option value="">-- Select Category --</option>
          <option value="UI">
            <span className="material-symbols-outlined">palette</span> UI
          </option>
          <option value="Builder">
            <span className="material-symbols-outlined">construction</span> Builder
          </option>
          <option value="Dashboard">
            <span className="material-symbols-outlined">dashboard</span> Dashboard
          </option>
          <option value="Integration">
            <span className="material-symbols-outlined">extension</span> Integration Web3
          </option>
          <option value="Performance">
            <span className="material-symbols-outlined">speed</span> Performances
          </option>
        </select>

        <div className="propose-feature-image-upload">
          <p>
            Upload an image, mockup, or screenshot to help illustrate your feature idea.
            Supported formats: JPG, PNG, GIF.
          </p>
          <label>Add a Visual (optional)</label>
          <input type="file" accept="image/*" onChange={handleFileChange} />
        </div>

        {errorMessage && <p className="error-text">{errorMessage}</p>}

        <div className="propose-feature-buttons">
          <button className="cancel-button" onClick={onClose}>
            Cancel
          </button>
          <button
            className="submit-button"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit Feature"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProposeFeatureModal;
