// src/components/DashboardUser/SignUp.js
import React, { useState } from "react";
import { db, doc, getDoc, setDoc } from "../../firebaseConfig";
import { requestAccess, signMessage } from "@stellar/freighter-api"; 
import "./SignUp.css";

function SignUp({ onClose }) {
  const [email, setEmail] = useState("");
  const [walletName, setWalletName] = useState("");
  const [walletId, setWalletId] = useState("");
  const [error, setError] = useState("");
  const [isWalletSigned, setIsWalletSigned] = useState(false);

  // Hard-coded wallet options, now including Freighter
  const walletOptions = [
    {
      name: "Phantom",
      icon: "https://firebasestorage.googleapis.com/v0/b/third--space.appspot.com/o/ImageWebSite%2FPopup%2Fphantom-logo.png?alt=media",
    },
    {
      name: "Metamask",
      icon: "https://firebasestorage.googleapis.com/v0/b/third--space.appspot.com/o/ImageWebSite%2FPopup%2Fmetamask-logo.png?alt=media",
    },
    {
      name: "Unstoppable",
      icon: "https://firebasestorage.googleapis.com/v0/b/third--space.appspot.com/o/ImageWebSite%2FPopup%2Funstoppablelogo.png?alt=media",
    },
    {
      name: "Freighter",
      icon: "https://firebasestorage.googleapis.com/v0/b/third--space.appspot.com/o/ImageWebSite%2FPopup%2Fstellar_logo.png?alt=media",
      
    },
  ];

  /**
   * handleWalletSelect:
   *   - Called when the user clicks one of the wallet buttons (Phantom, Metamask, Unstoppable, Freighter).
   *   - We connect + request a signature, and if successful, store the walletId and set isWalletSigned = true.
   */
  const handleWalletSelect = async (selectedWallet) => {
    setWalletName(selectedWallet);
    setError("");
    setIsWalletSigned(false);
    setWalletId("");

    try {
      if (selectedWallet === "Metamask") {
        if (!window.ethereum) {
          setError("Metamask not detected. Please install the extension.");
          return;
        }
        // Connect account
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const account = accounts[0];

        // Request signature
        const message = "Sign this message to create your account and confirm wallet ownership.";
        await window.ethereum.request({
          method: "personal_sign",
          params: [message, account],
        });

        setWalletId(account);
        setIsWalletSigned(true);

      } else if (selectedWallet === "Phantom") {
        if (!window.solana || !window.solana.isPhantom) {
          setError("Phantom wallet not detected. Please install Phantom.");
          return;
        }
        // Connect Phantom
        const resp = await window.solana.connect();
        const publicKey = resp.publicKey.toString();

        // Sign a message
        const message = new TextEncoder().encode(
          "Sign this message to create your account and confirm wallet ownership."
        );
        await window.solana.signMessage(message, "utf8");

        setWalletId(publicKey);
        setIsWalletSigned(true);

      } else if (selectedWallet === "Unstoppable") {
        // Example Unstoppable logic:
        // const uauth = new UAuth({ ... });
        // const authorization = await uauth.loginWithPopup();
        // const unstoppableUserId = authorization.idToken.sub;
        // if (!unstoppableUserId) throw new Error("No user ID from Unstoppable.");
        // setWalletId(unstoppableUserId);
        // setIsWalletSigned(true);

        setError("Unstoppable login not yet implemented in this example.");

      } else if (selectedWallet === "Freighter") {
        // ----- FREIGHTER LOGIC -----
        const accessObj = await requestAccess();
        if (accessObj.error) {
          throw new Error(accessObj.error.message);
        }
        const publicKey = accessObj.address;
        if (!publicKey) {
          throw new Error("Unable to retrieve public key from Freighter.");
        }

        // Ask the user to sign a message to confirm ownership
        const message = "Sign this message to create your account and confirm wallet ownership.";
        const signResult = await signMessage(message, { address: publicKey });
        if (signResult.error) {
          throw new Error(signResult.error);
        }
        console.log("Freighter signature:", signResult.signedMessage);

        setWalletId(publicKey);
        setIsWalletSigned(true);
      }
    } catch (err) {
      console.error("Error connecting/signing with wallet:", err);
      setError(`Failed to sign with ${selectedWallet}. Please try again.`);
    }
  };

  /**
   * handleSignUp:
   *   - Called when the user clicks "Create Account".
   *   - Must have a valid email, chosen wallet, and a completed signature (walletId set).
   */
  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Please enter your email.");
      return;
    }
    if (!walletName) {
      setError("Please select a wallet from the list.");
      return;
    }
    if (!walletId || !isWalletSigned) {
      setError("Please connect and sign with your wallet first.");
      return;
    }

    try {
      // We'll store the doc in Firestore keyed by the walletId.
      const userRef = doc(db, "users", walletId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        setError("An account with this wallet already exists. Please sign in instead.");
        return;
      }

      // Store everything under a "profile" object
      await setDoc(userRef, {
        profile: {
          email: email,
          walletId: walletId,
          chosenWalletName: walletName,
          createdAt: new Date().toISOString(),
        },
      });

      alert("Sign Up Successful! Your account has been created.");

      // Optionally mark them as logged in
      sessionStorage.setItem("isLoggedIn", "true");
      sessionStorage.setItem("userAccount", walletId);

      if (onClose) onClose();
      // or navigate("/dashboard") if using react-router
    } catch (err) {
      console.error("Sign Up error:", err);
      setError("Something went wrong. Please try again.");
    }
  };

  const isButtonDisabled = !email || !walletId || !isWalletSigned;

  return (
    <div>
      <form onSubmit={handleSignUp} className="signup-form">
        <label>Email Address *</label>
        <input
          type="email"
          placeholder="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontFamily:'Montserrat' }}>
          <hr className="signup-divider" />
          <div className="signup-or-text">and</div>
          <hr className="signup-divider" />
        </div>

        <label>Connect Your Wallet *</label>
        <p className="wallet-instructions">
          Choose & sign with your preferred wallet to create your account.
        </p>

        <div className="wallet-buttons">
          {walletOptions.map((wallet) => {
            const isSelected = walletName === wallet.name;
            const isSigned = isSelected && isWalletSigned;
            return (
              <button
                key={wallet.name}
                type="button"
                className={`wallet-option ${isSelected ? "selected" : ""} ${
                  isSigned ? "connected" : ""
                }`}
                onClick={() => handleWalletSelect(wallet.name)}
              >
                <img src={wallet.icon} alt={wallet.name} />
                {isSigned ? `${wallet.name} - Connected!` : wallet.name}
              </button>
            );
          })}
        </div>

        {error && <p className="error-text">{error}</p>}

        <button
          type="submit"
          className="create-account-btn"
          disabled={isButtonDisabled}
        >
          Create Account →
        </button>
      </form>
    </div>
  );
}

export default SignUp;
