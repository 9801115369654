import React from "react";
import "./DUProjectMaxPopup.css";

function DUProjectMaxPopup({ onClose }) {
    return (
        <div className="du-project-max-popup">
            <div className="du-project-max-popup-content">
                <div className="du-project-max-popup-top">
                    <p className="du-project-max-popup-top-title">Limit of project reached</p>
                    <p className="du-project-max-popup-top-text">You have reached the maximum number of projects (3). Please delete one of your existing projects.</p>
                </div>
                <button className="du-project-max-popup-btn" onClick={onClose}>I understand<i class="bi bi-arrow-right"></i></button>

            </div>
        </div>
    );
}

export default DUProjectMaxPopup;
