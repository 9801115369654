// src/components/PopupWallet.js
import React, { useState, useEffect } from "react";
import "../components/DashboardUser/Login.css";
import "../components/Root.css";
import { db, doc, getDoc, setDoc, updateDoc } from "../firebaseConfig";
import ReactGA from "react-ga";
import { useNavigate } from "react-router-dom";

// The advanced sign-up component
import SignUp from "../components/DashboardUser/SignUp";

// The advanced sign-in/wallet component
import WalletConnection from "../components/DashboardUser/WalletConnection";

// Initialize Google Analytics
ReactGA.initialize("G-83NKPT3B9E");

function PopupWallet({ onClose }) {
  const [hasStepData, setHasStepData] = useState(false);
  const [hasWalletData, setHasWalletData] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [walletAvailable, setWalletAvailable] = useState(true);

  const [isExistingUser, setIsExistingUser] = useState(false);
  const [customErrorMessage, setCustomErrorMessage] = useState("");
  const [showWalletOptions, setShowWalletOptions] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Toggle between sign up vs. sign in
  const [isSignUp, setIsSignUp] = useState(false);

  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("Welcome");
  const [headerDescription, setHeaderDescription] = useState(
    "If this is your first time, we will create an account for you!"
  );
  const [termsDecription, setTermsDescription] = useState("By signing in, you're agreeing to the ");
  const [accountMessage, setAccountMessage] = useState(" and ");

  const navigate = useNavigate();

  // You might use this if you want to do something when user logs in
  const onUserLogin = (userAccount) => {
    console.log("User logged in with wallet:", userAccount);
  };

  // Check if user has any existing wallet data in Firestore
  const checkWalletData = async () => {
    const userAccount = sessionStorage.getItem("userAccount");
    if (userAccount) {
      try {
        const docRef = doc(db, "users", userAccount);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          // example check
          if (data.selectedButtons && Object.keys(data.selectedButtons).length > 0) {
            setHasWalletData(true);
            setHasStepData(true);
          } else {
            setHasWalletData(false);
            setHasStepData(false);
          }
        } else {
          setHasWalletData(false);
          setHasStepData(false);
        }
      } catch (error) {
        console.error("Error fetching wallet data:", error);
        setHasWalletData(false);
        setHasStepData(false);
      }
      setAccounts([userAccount]);
    }
  };

  useEffect(() => {
    checkWalletData();
  }, []);

  useEffect(() => {
    console.log("Accounts:", accounts);
    console.log("Has Wallet Data:", hasWalletData);
  }, [accounts, hasWalletData]);

  // Google Analytics event
  const logEvent = (eventName, eventData) => {
    ReactGA.event({
      category: "User",
      action: eventName,
      label: eventData,
    });
  };

  // Save login event in Firestore
  const saveLoginEvent = async (userId, loginType) => {
    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);
    const timestamp = new Date().toISOString();

    if (userDoc.exists()) {
      await updateDoc(userRef, {
        lastLogin: timestamp,
        loginType,
        clicks: userDoc.data().clicks ? userDoc.data().clicks + 1 : 1,
      });
    } else {
      await setDoc(userRef, {
        userId,
        loginType,
        firstLogin: timestamp,
        lastLogin: timestamp,
        clicks: 1,
      });
    }

    console.log("Login event saved to Firestore:", userId);

    // Check the hostname and conditionally redirect
    if (window.location.hostname === "localhost") {
      // If you're on your local machine, navigate to the local dashboard route
      navigate("/dashboard");
    } else {
      // In production, navigate to the provided production URL
      navigate("../");
    }
  };


  const checkForWallet = () => {
    const hasWallet =
      !!window.ethereum || (window.solana && window.solana.isPhantom);
    setWalletAvailable(hasWallet);
    if (!hasWallet) {
      setCustomErrorMessage(
        "To use DAppzy, you need to connect a wallet. Please create one and then reload your page."
      );
    }
  };

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  useEffect(() => {
    setIsMobile(isMobileDevice());
    if (isMobileDevice()) {
      navigate("../get-started-mobile");
    }
    checkForWallet();
  }, [onClose, navigate]);

  // Clear state for sign in vs. sign up
  const resetState = () => {
    setIsSignUp(false);
    setIsExistingUser(false);
    setShowForgotPassword(false);
    setShowWalletOptions(false);
    setHeaderTitle("Welcome");
    setHeaderDescription("If this is your first time, we will create an account for you!");
  };

  const handleSignInClick = (event) => {
    event.preventDefault();
    resetState();
    setHeaderTitle("Welcome");
    setHeaderDescription("If this is your first time, we will create an account for you!");
    setIsSignUp(false);
    setTermsDescription("By signing in, you're agreeing to the ");
    setAccountMessage(" and ");
  };

  const handleSignUpClick = (event) => {
    event.preventDefault();
    resetState();
    setHeaderTitle("Sign Up");
    setHeaderDescription("Create your account with the method of your choice.");
    setIsSignUp(true);
    setTermsDescription("By clicking “Create Account”, you agree to our ");
    setAccountMessage(" and ");
  };

const handlTermsClick = () => {
  navigate("../terms");
}

const handlPolicyClick = () => {
  navigate("../privacy-policy")
}

  if (isMobile) {
    return null; // or a specific mobile UI
  }

  return (
    <div className="popup" id="popup" style={{ display: "flex" }}>
      <div className="popup-content">
        <div className="Sign-in-header">
          <h1>{headerTitle}</h1>
          <p>{headerDescription}</p>
        </div>

        {/* If user is in Sign Up mode, show the advanced SignUp form. Otherwise, show WalletConnection. */}
        {isSignUp ? (
          <SignUp onClose={onClose} />
        ) : (
          <WalletConnection
            saveLoginEvent={saveLoginEvent}
            logEvent={logEvent}
            checkForWallet={checkForWallet}
            onClose={onClose}
            checkWalletData={checkWalletData}
            setCustomErrorMessage={setCustomErrorMessage}
            walletAvailable={walletAvailable}
            onUserLogin={onUserLogin}
            customErrorMessage={customErrorMessage}
          />
        )}

        <p className="terms-links">
          {termsDecription}
          <a onClick={handlTermsClick}>
            <u>Terms</u>
          </a>
          {accountMessage}
          <a onClick={handlPolicyClick}>
            <u>Privacy Policy</u>
          </a>
        </p>

        {/* Toggle links at the bottom */}
        {!showForgotPassword ? (
          isSignUp ? (
            <p className="sign-in-redirect">
              Already have an account?{" "}
              <a href="#" onClick={handleSignInClick}>
                Sign In
              </a>
            </p>
          ) : (
            <p className="sign-in-redirect">
              Don't have an account yet ?{" "}
              <a href="#" onClick={handleSignUpClick}>
                Sign Up
              </a>
            </p>
          )
        ) : (
          <p className="sign-in-redirect">
            Remember your password?{" "}
            <a href="#" onClick={handleSignInClick}>
              Login
            </a>
          </p>
        )}
      </div>
      {/* <WalletCarousel /> or any other side UI if you want */}
    </div>
  );
}

export default PopupWallet;
