import DUQuotaRecap from "../DashboardUserComponents/DUQuotaRecap";

function ProfilePage({userAccount}) {
    return (
        <div className="dashboard-user-pages-content">
            <div className="dashboard-user-pages-content-left">
                <div className="dashboard-user-pages-content-row">

                </div>
            </div>
            <div className="dashboard-user-pages-content-right">
                <DUQuotaRecap userAccount={userAccount}/>

            </div>
        </div>
    );
}

export default ProfilePage;