// src/components/UpvotePromoCard.js
import React from "react";
import { useNavigate } from "react-router-dom";
import "./UpvotePromoCard.css"; // We'll define styles next
import '../../../Root.css';

function UpvotePromoCard({setCurrentPage}) {

  const handleClick = () => {
    // Navigate to your Feature Upvote page
    setCurrentPage('feature-upvote');
  };

  return (
    <div className="dashboard-user-pages-content-bloc upvote-promo-card">
      {/* Left image */}
      <img className="upvote-promo-card-image" src="https://firebasestorage.googleapis.com/v0/b/third--space.appspot.com/o/ImageDashboard%2FDashboard%20Redo%2Fhero-upvote.png?alt=media&token=813e261c-c352-4bec-87df-64b35b6af377" alt="Upvote" />

  

      {/* Right text content */}
      <div className="upvote-promo-card-content">
        <h3>Help Shape the Future of Third Space</h3>
        <p>Vote for the features you need most and help us decide what to build next.</p>
        <button onClick={handleClick} className="upvote-promo-button"> <i class="bi bi-capslock"></i> Upvote Now
       
        </button>
      </div>
    </div>
  );
}

export default UpvotePromoCard;
