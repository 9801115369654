// src/pages/DashboardUser.js

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DULeftBar from "../components/DashboardUser/DULeftBar";
import '../components/DashboardUser/DashboardUser.css';
import ProfilePage from "../components/DashboardUser/DashboardUserPages/ProfilePage";
import BillingPage from "../components/DashboardUser/DashboardUserPages/BillingPage";
import FeatureUpvotePage from "../components/DashboardUser/DashboardUserPages/FeatureUpvotePage";
import MainPage from "../components/DashboardUser/DashboardUserPages/MainPage";

function DashboardUser() {
  const [userAccount, setUserAccount] = useState(null);
  const [currentPage, setCurrentPage] = useState("main");
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = sessionStorage.getItem("isLoggedIn") === "true";
    const storedAccount = sessionStorage.getItem("userAccount");

    if (!isLoggedIn || !storedAccount) {
      // If user is not logged in, redirect to the login page
      navigate("/login");
    } else {
      setUserAccount(storedAccount);
    }
  }, [navigate]);

  const handleLogout = () => {
    // Clear session data
    sessionStorage.removeItem("isLoggedIn");
    sessionStorage.removeItem("userAccount");

    // Optionally clear localStorage if you also store user info there
    // localStorage.removeItem("userAccount");

    // Redirect to /login
    navigate("/login");
  };

  const renderPage = () => {
    switch (currentPage) {
      case "profile":
        return <ProfilePage userAccount={userAccount}  />;
      case "billing":
        return <BillingPage />;
      case "feature-upvote":
        return <FeatureUpvotePage userAccount={userAccount}  />;
      default:
        return <MainPage userAccount={userAccount} setCurrentPage={setCurrentPage}/>;
    }
  };


  return (
    <div>
      {userAccount ? (
        <div className="dashboard-user-wrapper">
          <DULeftBar
            onLogout={handleLogout}
            onNavigate={setCurrentPage}
            currentPage={currentPage}
          />
          <div className="dashboard-user-pages-wrapper">
            {renderPage()}
          </div>

          {/* <p>
            You are logged in as: <strong>{userAccount}</strong>
          </p> */}

        </div>

      ) : (
        <p>Loading Dashboard...</p>
      )}
    </div>
  );
}

export default DashboardUser;
