import React, { useState, useMemo } from "react";
import "./AllFeaturesList.css";

function AllFeaturesList({
  loading,
  features,
  userAccount,
  categoryIcons,
  onUpvote,
  onDownvote,
}) {
  // Add local state for the popup image
  const [previewImage, setPreviewImage] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("MostVotes");

  // Filter and sort features
  const filteredAndSortedFeatures = useMemo(() => {
    let result = [...features];

    // Apply search filter
    if (searchQuery) {
      result = result.filter(feat => 
        feat.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        feat.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Apply sorting
    switch (sortBy) {
      case "MostVotes":
        result.sort((a, b) => (b.voteCount || 0) - (a.voteCount || 0));
        break;
      case "Alphabetical":
        result.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case "Newest":
        result.sort((a, b) => {
          const dateA = a.createdAt?.toDate() || new Date(0);
          const dateB = b.createdAt?.toDate() || new Date(0);
          return dateB - dateA;
        });
        break;
      default:
        break;
    }

    return result;
  }, [features, searchQuery, sortBy]);

  if (loading) {
    return <p>Loading features...</p>;
  }

  // Function to handle showing the popup
  const handleShowImage = (imgUrl) => {
    setPreviewImage(imgUrl);
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setPreviewImage(null);
  };

  return (
    <div className="all-features-band">
      <div className="all-features-header">
        <div className="all-features-header-content">
          <h2>Vote for the Features You Want</h2>
          <p>Your voice counts! Click to vote and help prioritize new features.</p>
        </div>
        {/* Example row with search & sort - placeholders */}
        <div className="all-features-toolbar">
          <div className="all-features-toolbar-search">
            <i className="bi bi-search"></i>
            <input 
              type="text" 
              placeholder="Search..." 
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="sort-dropdown">
            <label>
              <span className="material-symbols-outlined">filter_list</span>
              Sort by
            </label>
            <select 
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            >
              <option value="MostVotes">Most Votes</option>
              <option value="Alphabetical">Alphabetical</option>
              <option value="Newest">Newest</option>
            </select>
          </div>
        </div>
      </div>

      {/* The grid of cards (3 columns max) */}
      <div className="all-features-grid">
        {filteredAndSortedFeatures.map((feat, index) => {
          const alreadyVotedUp = feat.upvoters?.includes(userAccount);
          const alreadyVotedDown = feat.downvoters?.includes(userAccount);

          // Category icon
          const iconEl = categoryIcons[feat.category] || (
            <span className="material-symbols-outlined">help</span>
          );

          // Rank (#1, #2, etc.)
          const rank = `#${index + 1}`;

          // If there's an attached file, store the URL
          const hasFile = feat.imageURL || feat.uploadFileURL;

          return (
            <div key={feat.id} className="feature-card">
              <div className="feature-rank">{rank}</div>
              <div className="feature-card-icon">{iconEl}</div>

              <div className="feature-card-content">
                <h3>{feat.title}</h3>
                <p>{feat.description}</p>

                {/* Instead of linking to the file, open a popup */}
                {hasFile && (
                  <button
                    type="button"
                    onClick={() => handleShowImage(hasFile)}
                    className="feature-attachment-link"
                  >
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: "14px", verticalAlign: "middle" }}
                    >
                      visibility
                    </span>
                    View attached file
                  </button>
                )}
              </div>

              <div className="feature-card-votes">
                <span className="vote-count-green">{feat.voteCount || 0}</span>

                <button
                  className={`vote-button-up ${alreadyVotedUp ? 'voted' : ''}`}
                  onClick={() => onUpvote(feat.id, feat.upvoters || [], feat.downvoters || [])}
                  title={alreadyVotedUp ? "Remove upvote" : "Upvote"}
                >
                  <i className={`bi ${alreadyVotedUp ? 'bi-capslock-fill' : 'bi-capslock'}`} style={{ color:  "#22C55E"  }}></i>
                </button>

                <button
                  className={`vote-button-down ${alreadyVotedDown ? 'voted' : ''}`}
                  onClick={() => onDownvote(feat.id, feat.upvoters || [], feat.downvoters || [])}
                  title={alreadyVotedDown ? "Remove downvote" : "Downvote"}
                >
                  <i className={`bi ${alreadyVotedDown ? 'bi-capslock-fill' : 'bi-capslock'}`} style={{ color: "#ED3B54", transform: "rotate(180deg)" }}></i>
                </button>
              </div>
            </div>
          );
        })}
      </div>

      {/* Popup modal if previewImage is set */}
      {previewImage && (
        <div className="image-preview-overlay" onClick={handleClosePopup}>
          <div className="image-preview-content" onClick={(e) => e.stopPropagation()}>
            <img src={previewImage} alt="Preview" />
            <button onClick={handleClosePopup} className="close-button">
              <span className="material-symbols-outlined">close</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AllFeaturesList;
