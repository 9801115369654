import DUEmailBloc from '../DashboardUserComponents/MainPage/DUEmailBloc';
import DUProfileRecap from '../DashboardUserComponents/MainPage/DUProfileRecap';
import DUProjectsBloc from '../DashboardUserComponents/MainPage/DUProjectsBloc';
import UpvotePromoCard from '../DashboardUserComponents/UpvotePage/UpvotePromoCard';
import DiscordCarousel from '../DashboardUserComponents/MainPage/DiscordCarousel';
import './MainPage.css';

function MainPage({ userAccount, setCurrentPage }) {
    return (
        <div className="dashboard-user-pages-content">
            <div className="dashboard-user-pages-content-wrapper">
                <div className="dashboard-user-pages-content-left">
                    <div className="dashboard-user-pages-content-bloc dashboard-user-pages-content-bloc-bg du-height-200">
                        <h1 className="dashboard-user-pages-content-h1">Welcome to dAppzy Builder</h1>
                        <p className="dashboard-user-pages-content-p">Bring your ideas to life in just a few clicks! Create, collaborate, and explore new possibilities with our intuitive builder.</p>
                        <img className="du-main-page-img" src='./images/nas-img.png'></img>
                    </div>
                    <DUProjectsBloc userAccount={userAccount} />
                    <div className="dashboard-user-pages-content-row">
                        <UpvotePromoCard setCurrentPage={setCurrentPage} />
                    </div>
                </div>
                <div className="dashboard-user-pages-content-right">
                    <DUProfileRecap userAccount={userAccount} />
                    <DUEmailBloc userAccount={userAccount} />
                    <DiscordCarousel userAccount={userAccount} />
                </div>
            </div>
        </div>
    );
}

export default MainPage;