function BillingPage() {
    return (
        <div className="dashboard-user-pages-content">
            <div className="dashboard-user-pages-content-left">
                <div className="dashboard-user-pages-content-row">

                </div>
            </div>
            <div className="dashboard-user-pages-content-right">

            </div>
        </div>
    );
}

export default BillingPage;