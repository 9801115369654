import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../../../firebaseConfig"; // Adjust path as needed
import DeletePopup from "../DeletePopup";
import CreateProjectPopup from "../CreateProjectPopup";
import DUProjectMaxPopup from "./DUProjectMaxPopup"; // Importer le composant
import "./DUProjectsBloc.css";

function DUProjectsBloc({ userAccount }) {
  const [projects, setProjects] = useState([]);
  const [activeMenuProjectId, setActiveMenuProjectId] = useState(null);
  const [deletePopupProject, setDeletePopupProject] = useState(null);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [showMaxProjectsPopup, setShowMaxProjectsPopup] = useState(false); // État pour la popup
  const [currentIndex, setCurrentIndex] = useState(0);
  const projectsContainerRef = useRef(null);

  // Load user’s existing projects
  const loadUserProjects = async () => {
    if (!userAccount) return;
    try {
      const projectsRef = collection(db, "projects", userAccount, "ProjectRef");
      const q = query(projectsRef, where("userId", "==", userAccount));
      const querySnapshot = await getDocs(q);
      const fetchedProjects = [];
      querySnapshot.forEach((docSnap) => {
        fetchedProjects.push({ id: docSnap.id, ...docSnap.data() });
      });
      setProjects(fetchedProjects);
    } catch (error) {
      console.error("Error loading user projects:", error);
    }
  };

  useEffect(() => {
    loadUserProjects();
  }, [userAccount]);

  // Base URL logic
  const isLocalhost = window.location.hostname === "localhost";
  const baseUrl = isLocalhost
    ? "http://localhost:3001"
    : "https://builder.dappzy.io";

  // Open the builder with a given projectId
  const handleProjectClick = (projectId) => {
    console.log("Project clicked:", projectId);
    window.location.href = `${baseUrl}/?userId=${userAccount}&projectId=${projectId}`;
  };

  // Show the "Create Project" popup
  const handleNewProjectClick = () => {
    console.log("New project button clicked");
    if (projects.length >= 3) {
      setShowMaxProjectsPopup(true); // Affiche la popup personnalisée
      return;
    }
    setShowCreatePopup(true);
  };

  // Called when user finishes creating a new project in the popup
  const handleProjectCreated = (newProjectId) => {
    console.log("Project created:", newProjectId);
    loadUserProjects();
    setShowCreatePopup(false);
    // Then redirect them to the builder
    window.location.href = `${baseUrl}/?userId=${userAccount}&projectId=${newProjectId}`;
  };

  // Show the delete confirmation popup for the given project
  const openDeletePopup = (project, e) => {
    e.stopPropagation();
    console.log("Delete popup opened for project:", project.id);
    setActiveMenuProjectId(project.id);
    setDeletePopupProject(project);
  };

  // Actually delete the project from Firestore
  const handleDeleteProject = async () => {
    if (!deletePopupProject) return;
    try {
      const projectRef = doc(
        db,
        "projects",
        userAccount,
        "ProjectRef",
        deletePopupProject.id
      );
      await deleteDoc(projectRef);
      loadUserProjects(); // refresh after deleting
    } catch (error) {
      console.error("Error deleting project:", error);
    }
    // Close popups
    setDeletePopupProject(null);
    setActiveMenuProjectId(null);
  };

  // Rename project example
  const handleRenameProject = async (projectId, e) => {
    e.stopPropagation();
    const newTitle = window.prompt("Enter new project name:");
    if (newTitle && newTitle.trim() !== "") {
      try {
        const projectRef = doc(db, "projects", userAccount, "ProjectRef", projectId);
        await updateDoc(projectRef, {
          "websiteSettings.siteTitle": newTitle.trim(),
          lastUpdated: serverTimestamp(),
        });
        loadUserProjects();
      } catch (error) {
        console.error("Error renaming project:", error);
      }
    }
    setActiveMenuProjectId(null);
  };

  // Close popups if user clicks outside
  useEffect(() => {
    const handleClickOutside = () => {
      if (activeMenuProjectId !== null) {
        setActiveMenuProjectId(null);
      }
      if (deletePopupProject !== null) {
        setDeletePopupProject(null);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [activeMenuProjectId, deletePopupProject]);

  // Handle scrolling with arrows
  const handleScroll = (direction) => {
    console.log("Scroll direction:", direction);
    setCurrentIndex((prevIndex) => {
      if (direction === "left" && prevIndex > 0) {
        return prevIndex - 1;
      } else if (direction === "right" && prevIndex < projects.length - 1) {
        return prevIndex + 1;
      }
      return prevIndex;
    });
  };

  // Auto-scroll to the current index
  useEffect(() => {
    if (projectsContainerRef.current && projects.length > 0) {
      const cardWidth = projectsContainerRef.current.firstChild.clientWidth;
      projectsContainerRef.current.scrollTo({
        left: currentIndex * cardWidth,
        behavior: "smooth",
      });
    }
  }, [currentIndex, projects]);

  return (
    <div className="dashboard-user-pages-content-bloc">
      <div className="dashboard-user-pages-content-bloc-top">
        <p className="dashboard-user-pages-content-bloc-top-title">My Projects</p>
        <div className="dashboard-user-pages-content-bloc-top-button-box">
          <a
            className="dashboard-user-pages-content-bloc-top-button-purple"
            onClick={handleNewProjectClick}
          >
            New project
            <span className="material-symbols-outlined">add</span>
          </a>
          <div className="dashboard-user-pages-content-bloc-top-button-arrow-box">
            <a onClick={() => handleScroll("left")}>
              <span className="material-symbols-outlined">chevron_left</span>
            </a>
            <a onClick={() => handleScroll("right")}>
              <span className="material-symbols-outlined">chevron_right</span>
            </a>
          </div>
        </div>
      </div>

      <div
        className="du-project-bloc-content"
        ref={projectsContainerRef}
      >
        {projects.map((project) => (
          <div
            key={project.id}
            className="du-project-bloc-card"
            onClick={() => handleProjectClick(project.id)}
            style={{ position: "relative", flex: "0 0 auto" }}
          >
            <img
              className="du-project-bloc-card-img"
              src={
                project.websiteSettings?.thumbnailUrl || "./images/bg-gradient.png"
              }
              alt="Project Thumbnail"
            />
            <div className="du-project-bloc-card-bottom">
              <p className="du-project-bloc-card-bottom-text">
                {project.websiteSettings?.siteTitle || "Project Name"}
              </p>
              <span
                className="material-symbols-outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveMenuProjectId(
                    project.id === activeMenuProjectId ? null : project.id
                  );
                }}
              >
                more_vert
              </span>

              {/* The popup menu for Edit/Settings/Delete */}
              {activeMenuProjectId === project.id && (
                <div className="popup-menu">
                  <button onClick={() => handleProjectClick(project.id)}>
                    Edit
                  </button>
                  <button onClick={(e) => handleRenameProject(project.id, e)}>
                    Settings
                  </button>
                  {/* The DELETE button with trash icon */}
                  <button
                    style={{ color: "red" }}
                    onClick={(e) => openDeletePopup(project, e)}
                  >
                    <span className="material-symbols-outlined">delete</span>
                  </button>
                </div>
              )}

              {/* If user clicked the delete button for this project, show DeletePopup */}
              {deletePopupProject && deletePopupProject.id === project.id && (
                <DeletePopup
                  title="Are you sure you want to delete this project?"
                  warningMessage={`You are about to permanently delete the project: "${deletePopupProject.websiteSettings?.siteTitle}"`}
                  description="This action CANNOT be undone. All files, assets, and associated data will be deleted and cannot be recovered. Please make sure you have backed up any important information before proceeding."
                  confirmLabel={deletePopupProject.websiteSettings?.siteTitle || ""}
                  confirmPlaceholder={`${deletePopupProject.websiteSettings?.siteTitle}`}
                  deleteButtonText="Delete Project"
                  cancelButtonText="Cancel"
                  isProject={true} // or pass false if you want user-type text
                  onConfirm={handleDeleteProject}
                  onCancel={() => setDeletePopupProject(null)}
                />
              )}
            </div>
          </div>
        ))}

        {/* Conditionally render the "New Project" card */}
        {projects.length === 0 && (
          <div
            className="du-project-bloc-card du-project-bloc-card-no-project"
            onClick={handleNewProjectClick}
          >
            <i className="bi bi-folder-plus"></i>
            <p className="du-project-bloc-card-text-white">New Project</p>
          </div>
        )}
      </div>

      {/* Render CreateProjectPopup if "showCreatePopup" is true */}
      {showCreatePopup && (
        <CreateProjectPopup
          userAccount={userAccount}
          onClose={() => setShowCreatePopup(false)}
          onProjectCreated={handleProjectCreated}
        />
      )}

      {/* Render DUProjectMaxPopup if "showMaxProjectsPopup" is true */}
      {showMaxProjectsPopup && (
        <DUProjectMaxPopup
          onClose={() => setShowMaxProjectsPopup(false)}
        />
      )}
    </div>
  );
}

export default DUProjectsBloc;
