import React from 'react';
import './DUQuotaRecap.css';
import { useProjectCount } from './useProjectCount'; // Adjust the path if needed

function DUQuotaRecap({ userAccount }) {
  const projectCount = useProjectCount(userAccount);

  const quotas = [
    { title: "Total projects created", current: projectCount, max: 3 },
    { title: "Storage used (GB)", current: 5, max: 10 },
    { title: "API Calls made", current: 200, max: 500 },
    { title: "Team members added", current: 2, max: 5 }
  ];

  return (
    <div className="dashboard-user-pages-content-bloc">
      <div className="dashboard-user-pages-content-bloc-top">
        <div className='dashboard-user-pages-content-bloc-top-content'>
          <p className='dashboard-user-pages-content-bloc-top-title'>Current Plan</p>
          <p className='dashboard-user-pages-content-bloc-top-text'>Freemium</p>
        </div>
        <a href='' className='dashboard-user-pages-content-bloc-top-button-purple-round'>
          <span className="material-symbols-outlined">arrow_upload_progress</span>Upgrade Now
        </a>
      </div>
      <div className='du-quota-recap-content'>
        {quotas.map((quota, index) => {
          const progressWidth = (quota.current / quota.max) * 100 + "%";
          return (
            <div className='du-quota-recap-card' key={index}>
              <div className='du-quota-recap-card-top'>
                <p className='du-quota-recap-card-top-text'>{quota.title}</p>
                <div className='du-quota-recap-card-top-quota-box'>
                  <p className='du-quota-recap-card-top-quota-box-text'>{quota.current}</p>
                  <p className='du-quota-recap-card-top-quota-box-text'>/</p>
                  <p className='du-quota-recap-card-top-quota-box-text'>{quota.max}</p>
                </div>
              </div>
              <div className='du-quota-recap-card-bottom'>
                <div className='du-quota-recap-card-bottom-progress' style={{ width: progressWidth }}></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DUQuotaRecap;
