import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for metadata handling
import '../components/Waitlist/Waitlist.css'; // Optional: create a CSS file for styling if you want
import NavBar from '../components/website/GenericComponents/NavBar';
import Footer from '../components/website/GenericComponents/Footer'
import WaitlistInput from '../components/Waitlist/WaitlistInput';

// Separator Component
const Separator = ({ text }) => {
  return (
    <div className="separator">
      <span className="separator-text">{text}</span>
    </div>
  );
};

// Header of the waitlist page 
const HeaderWaitlist = ({ }) => {
  return (
    <div className='header-waitlist'>
      <Separator text="Join Waitlist" />

      <h1>Be The First To Access 3S Builder</h1>
      <p>Join our waitlist and get notified when go live. Unlock early access to our no-code Web3
        platform and be part of the next generation of decentralized app creation.</p>
      <WaitlistInput />
    </div>
  );
}

// Feature Card Component
const FeatureCard = ({ icon, title, description, size }) => {
  return (
    <div className="feature-card-waitlist">
      <i className={`bi bi-${icon}`} style={{ fontSize: size }}></i> {/* Apply size here */}
      <div className='feature-card-waitlist-infos'>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

// Feature Section
const FeatureSection = () => {
  return (
    <div className='waitlist-features'>
      <Separator text="Features" />

      <h2>Unlock Exclusive Early Access to 3S Builder</h2>
      <div className='waitlist-elipse'></div>
      <p>Join the waitlist to be the first in line for early access, special perks, and the chance to shape the future 
        of decentralized app creation. Lead the next generation of Web3 development with us.</p>
      <section className="features">
        <FeatureCard
          icon="calendar"
          title="Get Early Access"
          description="Be among the first to experience our no-code platform. Gain early access to create, customize, and launch your projects before anyone else."
          size="40px" /* Set icon size */
        />
        <FeatureCard
          icon="gift"
          title="Founder's Special"
          description="As a waitlist member, get access to special discounts and bonuses only available during the platform's early launch phase."
          size="50px" /* Set icon size */
        />
        <FeatureCard
          icon="chat"
          title="Co-Create With Us"
          description="Have a say in how our platform evolves. As a waitlist member, your feedback will help guide our development and influence new features."
          size="45px" /* Set icon size */
        />
      </section>
    </div>
  );
};

// FAQ Section
const FAQSection = () => {
  const [faqOpen, setFaqOpen] = useState([false, false, false, false]);

  const toggleFaq = (index) => {
    const newFaqState = faqOpen.map((isOpen, i) => (i === index ? !isOpen : isOpen));
    setFaqOpen(newFaqState);
  };

  const faqs = [
    { question: 'How do I secure my early access? ', answer: 'You will receive an email 3 weeks before launch, guiding you to connect your wallet and claim your NTT (Non-Transferable Token) that grants early access and exclusive perks.' },
    { question: 'When will the platform launch?', answer: "We expect to launch in the next 6 months, and you'll be notified via email as soon as we have an official date for early access. Learn more by joining our discord : https://discord.gg/BSgQ6whg8u" },
    { question: 'Is the waitlist limited? ', answer: 'Yes, spots are limited to ensure that early access users get the best experience. However, we will be opening access in multiple batches, so more users will have the opportunity to test the platform. We recommend signing up as soon as possible to secure your spot in the next available batch.' },
    { question: 'Can I keep my early access perks after launch?', answer: 'Yes! Any perks you earn during early access, including discounts and special badges, will carry over when the platform officially launches.' },
  ];

  return (
    <div className='waitlist-faq-waitlist'>
      <div className='waitlist-faq-elipse'></div>

      <div className='faq-waitlist-head'>
        <Separator text="FAQs" />

        <h2>How Does the Waitlist Work?</h2>
        <p>Join the waitlist to be first in line for early access, exclusive perks, and the opportunity to help shape 
          the future of decentralized app creation. We'll guide you through the process, ensuring you're ready
          to unlock your benefits when the platform launches.</p>
      </div>
      <section className="waitlist-faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="waitlist-faq-item">
            <div className="waitlist-faq-question" onClick={() => toggleFaq(index)}>
              <span>{faq.question}</span>
              <button className="waitlist-toggle-btn">{faqOpen[index] ? '-' : '+'}</button>
            </div>
            {faqOpen[index] && <div className="waitlist-faq-answer">{faq.answer}</div>}
          </div>
        ))}
      </section>
    </div>
  );
};

const WaitlistPage = () => {
  return (
    <>
      <Helmet>
        <title>Join the 3S Builder Waitlist - Early Access to Our No-Code Web3 Platform</title>
        <meta
          name="description"
          content="Join the 3S Builder waitlist for early access to our no-code Web3 platform. Get exclusive perks and be part of the future of decentralized app creation."
        />
      </Helmet>
      <NavBar />
      <div className="Waitlistpage">
        <HeaderWaitlist />
        <FeatureSection />
        <FAQSection />
      </div>
      <Footer />
    </>
  );
};

export default WaitlistPage;
